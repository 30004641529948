/* global gtag */
/**
 * Naveseni akci na tlacitka pro tisk a odeslani kuponu
 *
 * Po kliknuti na tlacitko dojde k odeslani udalosti do GA
 */
( function() {
	window.LatkyTM = window.LatkyTM || {};
	var LatkyTM = window.LatkyTM;

	LatkyTM.init = function() {
		window.addEventListener( "load", function() {
			if ( !window.google_tag_manager ) {
				console.warn( "GTM not present" );
				return false;
			}

			console.info( "LatkyTm init" );
			LatkyTM._initCoupons();
			LatkyTM._initHP();
			LatkyTM._initCommon();
		} );
	};

	/**
	 * Obecna detekce kliknuti na element
	 * oznaceny atributem data-use_gtm="true"
	 *
	 * Element musi dale obsahovat atributy
	 * data-gtm_category a data-gtm_label  pro urceni
	 * hodnot event_category a event_label
	 * Element muze obsahovat volitelny atribut data-gtm_action
	 * pro urceni hodnoty event_action pro GA [default: "Klik"
	 */
	LatkyTM._initCommon = function() {
		$( "*[data-gtm_enable='true']" ).click( function() {
			var element = $( this );
			var gtmEventAction = element.data( "gtm_action" ) || "Klik";
			var gtmEventCategory = element.data( "gtm_category" ) || null;
			var gtmEventLabel = element.data( "gtm_label" ) || null;

			/* Debug
			console.log( "event", gtmEventAction, {
				"send_to": [ "default", "testing" ],
				"event_category": gtmEventCategory,
				"event_label": gtmEventLabel
			} );
			*/

			gtag( "event", gtmEventAction, {
				"send_to": [ "default", "testing" ],
				"event_category": gtmEventCategory,
				"event_label": gtmEventLabel
			} );
		} );
	};

	LatkyTM._initHP = function() {

		// Mereni kliknuti na bannery vedouci na dumlatek.cz
		// (druha skupina banneru pod odkazem na slevovy program
		$( ".body_main_index" +
				" div#container-banner-lists > ul:last-child > li.list__item a" )
			.click( function() {
				var bannerId = $( this ).find( "img.img-banner" ).attr( "id" );
				gtag( "event", "Klik", {
					"send_to": [ "default", "testing" ],
					"event_category": "Bannery DůmLátek.cz",
					"event_label": bannerId
				} );
			} );

	};

	LatkyTM._initCoupons = function() {

		// Mereni kliknuti na odkaz pro tisk slevoveho kuponu
		$( "div.coupon-buttons > a.btn.btn-primary.js-btn-print" ).click( function() {
			var couponText = $( this )
				.parents( ".coupon" )
				.find( ".coupon-text .coupon-text-header" )
				.text().trim();

			gtag( "event", "Vytisknout", {
				"send_to": [ "default", "testing" ],
				"event_category": "Kupóny",
				"event_label": couponText
			} );
		} );

		// Mereni kliknuti na odkaz pro poslani slevoveho kuponu emailem
		$( "div.coupon-buttons > a:nth-child(2)" ).click( function() {
			var couponText = $( this )
				.parents( ".coupon" )
				.find( ".coupon-text .coupon-text-header" )
				.text().trim();

			gtag( "event", "Poslat e-mailem", {
				"send_to": [ "default", "testing" ],
				"event_category": "Kupóny",
				"event_label": couponText
			} );
		} );

		return true;
	};
} )( this );
