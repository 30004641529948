// Zajisti vyber dorucovaci posty ve vyberu dopravy a platby
( function( window, undefined ) {
	"use strict";

	var $ = window.jQuery, DUMLATEK = window.DUMLATEK;
	var selectorBranchNeededField = "input[data-branch_needed='1'][name='delivery_method_id']";

	DUMLATEK.deliveryServiceBranchSelector = function() {
		$( "#form_delivery_service_branches_set_branch input.ui-autocomplete-input" )
			.on( "autocompleteselect", function( event, ui ) {
				event.preventDefault();
				$( event.target ).val( ui.item.value );
				$( event.target ).parents( "form" ).submit();
			} )
		.on( "autocompletefocus", function( event, ui ) {
			event.preventDefault();
			$( event.target ).val( ui.item.label );
		} );

		// Otevreni dialogu po zvoleni dorucovaci sluzby s vyberem pobocky
		$( selectorBranchNeededField  )
			.unbind( "change.delivery" )
			.bind( "change.delivery", function() {
			if ( $( this ).is( ":checked" ) ) {

				$( this ).parents( "li" )
					.find( ".delivery_service_branch .branch_button > a" )
					.trigger( "click" );
			}
		} );

	};
} )( this );

